"use strict";

var prices = null;
var regexPatterns = {
  date: {
    DMY: /\d{2}\/\d{2}\/\d{4}/,
    YMD: /\d{4}-\d{2}-\d{2}/
  }
};
var travelInsuranceAnswer = $('[name="form[travel-insurance-answer]"]');
var insuranceDateFields = $('#form-field-travel-insurance-start-date, #form-field-travel-insurance-end-date');
var insuranceDateFieldsArray = ['travel-insurance-start-date', 'travel-insurance-end-date'];
var strToDate = function strToDate(value) {
  if (typeof value !== "string" || value === '') {
    return false;
  }
  var groups;
  if (regexPatterns.date.DMY.test(value)) {
    groups = {
      day: value.substr(0, 2),
      month: value.substr(3, 2),
      year: value.substr(6, 4)
    };
  } else if (regexPatterns.date.YMD.test(value)) {
    groups = value.match(regexPatterns.date.YMD).groups;
    groups = {
      day: value.substr(8, 4),
      month: value.substr(5, 2),
      year: value.substr(0, 4)
    };
  } else {
    return false;
  }
  return new Date(groups.year, groups.month - 1, groups.day);
};
var calcInsurancePrice = function calcInsurancePrice() {
  var startDate = strToDate($('#form-field-travel-insurance-start-date').val());
  var endDate = strToDate($('#form-field-travel-insurance-end-date').val());
  if (prices !== null && startDate && endDate && startDate <= endDate) {
    return ((Math.round(Math.abs((endDate - startDate) / (24 * 60 * 60 * 1000))) + 1) * prices['insurance']).toFixed(2);
  }
  return false;
};
var showInsurancePrice = function showInsurancePrice() {
  var insurancePrice = calcInsurancePrice();
  if (typeof insurancePrice === "string") {
    $('#insurance-price').text(insurancePrice);
    $('.insurance-text .text-small').fadeIn(200);
    return true;
  }
  $('.insurance-text .text-small').fadeOut(200);
  return false;
};
var setInsuranceTermsVisibility = function setInsuranceTermsVisibility(state) {
  var insuranceTerms = $('.insurance-terms-row');
  if (state) {
    insuranceTerms.removeClass('hidden');
  } else {
    insuranceTerms.addClass('hidden');
    $('[name="form[insurance-terms]"]').prop('checked', false);
  }
};
var loadInsurancePrices = function loadInsurancePrices(data) {
  prices = data;
  $('#insurance-price-per-day').text(" \u20AC ".concat(prices['insurance'].toFixed(2)));
  showInsurancePrice();
};
if (window.insuranceEnabled) {
  insuranceDateFieldsArray = ['travel-insurance-start-date', 'travel-insurance-end-date'];
  (function () {
    $('[name="form[nationality-country]"]').on('change', function () {
      if (![undefined, null, ''].includes($(this).val()) && $('#form-field-nationality-country').val() !== null && $('#form-field-nationality-country').val() !== undefined) {
        showInsurancePrice();
      }
    });
  }).call(null);
  insuranceDateFields.on('change', function (e) {
    validateField($(this), e);
    showInsurancePrice();
  });
  document.addEventListener("DOMContentLoaded", function () {
    $('.insurance-mobile-content').hide();
    $('.insurance-expand').on('click', function () {
      $('.insurance-expand').fadeOut(300);
      $('.insurance-mobile-content').slideDown(300);
    });
    travelInsuranceAnswer.on('change', function (e) {
      validateField($(this), e);
    });
    $('[name="form[travel-insurance]"]').on('change', function () {
      $('[name="form[travel-insurance]"]').closest('label').removeClass('with-error').addClass('with-success');
      var checkedTravelInsuranceValue = $('[name="form[travel-insurance]"]:checked').val();
      if (checkedTravelInsuranceValue === '1') {
        insuranceDateFields.removeClass('with-error with-success');
        travelInsuranceAnswer.prop('checked', false).closest('label').removeClass('with-error with-success');
        $('.insurance-text .text-small').fadeOut(200);
        $('.travel-insurance-answer, .insurance-additional-description, .insurance-container .disable-input').fadeIn(300);
        travelInsuranceAnswer.closest('.row').fadeIn(300);
      } else {
        $('.travel-insurance-answer, .insurance-additional-description, .insurance-container .disable-input').fadeOut(300);
        $('#form-field-travel-insurance-start-date, #form-field-travel-insurance-end-date').val('');
        travelInsuranceAnswer.closest('.row').fadeOut(300);
      }
      setInsuranceTermsVisibility($('[name="form[travel-insurance-answer]"]:checked').val() === '1' && checkedTravelInsuranceValue === '1');
    });
    $('[href="#insurance-modal"]').on('click', function () {
      $('html').css({
        'overflow-y': 'hidden'
      });
      $('.insurance-modal').fadeIn(300);
    });
    $('.insurance-modal').on('click', function (e) {
      if (e.target === this) {
        $('html').css({
          'overflow-y': ''
        });
        $('.insurance-modal').fadeOut(300);
      }
    });
    $('.insurance-modal-close').on('click', function () {
      $('html').css({
        'overflow-y': ''
      });
      $('.insurance-modal').fadeOut(300);
    });
    $('[name="form[insurance-terms]"]').on('change', function () {
      if ($(this).is(':checked')) {
        $(this).closest('label').removeClass('with-error').addClass('with-success');
        console.log('checked 1');
      } else {
        $(this).closest('label').addClass('with-error').removeClass('with-success');
        console.log('not checked 1');
      }
    });
    travelInsuranceAnswer.on('change', function () {
      setInsuranceTermsVisibility($('[name="form[travel-insurance-answer]"]:checked').val() === '1' && $('[name="form[travel-insurance]"]:checked').val() === '1');
      if ($(this).val() === "0") {
        $('#form-field-travel-insurance-start-date, #form-field-travel-insurance-end-date').val('').removeClass('with-error with-success');
      }
    });
    window.addEventListener('hashchange', function (evt) {
      if (evt.newURL && evt.oldURL) {
        if (evt.oldURL.split('#')[1] === 'insurance-modal' && evt.newURL.indexOf('#insurance-modal') === -1) {
          $('html').css({
            'overflow-y': ''
          });
          $('.insurance-modal').fadeOut(300);
        }
      }
    });
  });
}
window.validateInsuranceFields = function ($field, event) {
  var isValid = true;
  if ($field.is(':visible') && ['form-field-travel-insurance-start-date', 'form-field-travel-insurance-end-date'].indexOf($field.attr('id')) !== -1) {
    var startDateTime = strToDate(document.querySelector('#form-field-travel-insurance-start-date').value);
    var endDateTime = strToDate(document.querySelector('#form-field-travel-insurance-end-date').value);
    var minDate = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * (insuranceMinDays - 1)).getTime();
    if (event && !$(event.target).hasClass('submit-btn') && ($field.attr('id') === 'form-field-travel-insurance-start-date' && !startDateTime || $field.attr('id') === 'form-field-travel-insurance-end-date' && !endDateTime) && $('[name="form[travel-insurance-answer]"]:checked').val() !== '0') {
      return;
    }
    if (!startDateTime) {
      if ($field.attr('id') === 'form-field-travel-insurance-start-date') {
        $('#form-field-travel-insurance-start-date').removeClass('with-success').addClass('with-error');
        isValid = false;
      }
    }
    if (!endDateTime) {
      if ($field.attr('id') === 'form-field-travel-insurance-end-date') {
        $('#form-field-travel-insurance-end-date').removeClass('with-success').addClass('with-error');
        isValid = false;
      }
    }
    if (startDateTime && endDateTime) {
      if ($field.attr('id') === 'form-field-travel-insurance-start-date' && startDateTime.getTime() < minDate) {
        $('#form-field-travel-insurance-start-date').removeClass('with-success').addClass('with-error');
        isValid = false;
      } else if ($field.attr('id') === 'form-field-travel-insurance-end-date' && endDateTime.getTime() < minDate) {
        $('#form-field-travel-insurance-end-date').removeClass('with-success').addClass('with-error');
        isValid = false;
      }
      if (startDateTime.getTime() >= minDate && endDateTime.getTime() >= minDate) {
        if (startDateTime.getTime() > endDateTime.getTime()) {
          isValid = false;
          $('#form-field-travel-insurance-end-date, #form-field-travel-insurance-start-date').removeClass('with-success').addClass('with-error');
        } else {
          $('#form-field-travel-insurance-end-date, #form-field-travel-insurance-start-date').removeClass('with-error').addClass('with-success');
          showInsurancePrice();
          setInsuranceTermsVisibility($('[name="form[travel-insurance-answer]"]:checked').val() === '1' && $('[name="form[travel-insurance]"]:checked').val() === '1');
        }
      }
    }
  }
  if ($field.attr('name') === 'form[travel-insurance-answer]') {
    setInsuranceTermsVisibility($('[name="form[travel-insurance-answer]"]:checked').val() === '1' && $('[name="form[travel-insurance]"]:checked').val() === '1' && $('#form-field-travel-insurance-start-date').trigger('change') && $('#form-field-travel-insurance-end-date').trigger('change'));
    if ($('[name="form[travel-insurance]"]:checked').val() === '1') {
      var travelInsuranceAnswerValue = $('[name="form[travel-insurance-answer]"]:checked').val();
      if (travelInsuranceAnswerValue === '1') {
        $('.insurance-start-date, .insurance-end-date').fadeIn(300);
        travelInsuranceAnswer.closest('label').removeClass('with-error with-success');
        $('[name="form[travel-insurance-answer]"][value="1"]').closest('label').addClass('with-success');
        showInsurancePrice();
      } else if (travelInsuranceAnswerValue === '0') {
        $('.insurance-text .text-small').fadeOut(200);
        $('.insurance-start-date, .insurance-end-date').fadeOut(300);
        travelInsuranceAnswer.closest('label').removeClass('with-error with-success');
        $('[name="form[travel-insurance-answer]"][value="0"]').closest('label').addClass('with-success');
      } else {
        travelInsuranceAnswer.closest('label').removeClass('with-success').addClass('with-error');
        isValid = false;
      }
    }
  }
  if ($field.attr('name') === 'form[insurance-terms]') {
    if ($field.is(':checked')) {
      $field.closest('label').removeClass('with-error').addClass('with-success');
    } else {
      $field.closest('label').addClass('with-error').removeClass('with-success');
    }
  }
  return isValid;
};
var addInsuranceDatepicker = function addInsuranceDatepicker() {
  $('#form-field-travel-insurance-start-date').on('change', function () {
    var selectedDate = strToDate($('#form-field-travel-insurance-start-date').val());
    if (window.showBasicDatePicker) {
      $('#form-field-travel-insurance-end-date').attr('min', $('#form-field-travel-insurance-start-date').val());
    } else {
      $("#form-field-travel-insurance-end-date").datepicker('hide').datepicker('hide').datepicker("destroy");
      var minDate = selectedDate ? Math.abs(Math.ceil((selectedDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))) : insuranceMinDays;
      $('#form-field-travel-insurance-end-date').datepicker({
        minDate: minDate >= insuranceMinDays ? minDate : insuranceMinDays,
        dateFormat: "yy-mm-dd",
        timepicker: false,
        prevText: "",
        nextText: "",
        numberOfMonths: window.innerWidth > 720 ? 2 : 1
      });
    }
  });
  if (window.showBasicDatePicker) {
    var ftoday = new Date();
    var minDate = new Date(ftoday.getTime() + insuranceMinDays * 24 * 60 * 60 * 1000);
    var minDateMonth = minDate.getMonth() + 1;
    var minDateDay = minDate.getDate();
    if (minDateMonth < 10) {
      minDateMonth = '0' + minDateMonth;
    }
    if (minDateDay < 10) {
      minDateDay = '0' + minDateDay;
    }
    $('#form-field-travel-insurance-start-date, #form-field-travel-insurance-end-date').attr({
      'type': 'date',
      'min': minDate.getFullYear() + '-' + minDateMonth + '-' + minDateDay
    });
  } else {
    insuranceDateFields.datepicker({
      minDate: insuranceMinDays,
      dateFormat: "yy-mm-dd",
      timepicker: false,
      prevText: "",
      nextText: "",
      numberOfMonths: window.innerWidth > 720 ? 2 : 1
    });
  }
};