"use strict";

/* global Storage */
/* global formLocalStorageKey */
var adddays = 2;
var isStorageUsing = true;
var supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
function isSupportForDateInput() {
  $('body').append('<input id="hiddenInput" type="date" value="text-value">');
  var isSupport = $('#hiddenInput').val() !== 'text-value';
  $('#hiddenInput').remove();
  return isSupport;
}
var showBasicDatePicker = supportsTouch;
if (supportsTouch) {
  if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent) || !isSupportForDateInput()) {
    showBasicDatePicker = false;
  }
}

//Invoice hidden form open
invoiceHiddenForm();
function invoiceHiddenForm() {
  var $iformSelector = $('[name="form[invoice]"]');
  $iformSelector.change(function () {
    var $obj = $(this);
    var checked = $obj.prop('checked');
    if (checked) {
      $('.invoice-hidden-row').removeClass('hidden');
      formLabelsEqual();
    } else {
      $('.invoice-hidden-row').addClass('hidden');
      formLabelsEqual();
    }
  }).trigger('change');
}
function setCalendarSettings() {
  $.ajax({
    url: '/getCalendar/',
    method: "GET",
    async: false,
    success: function success(result) {
      result = JSON.parse(result);
      if (typeof result.settings.flightOffset !== 'undefined') {
        adddays = parseInt(result.settings.flightOffset);
      }
      setDateTypeField();
    },
    error: function error() {
      setDateTypeField();
    }
  });
}
function setDateTypeField() {
  if (document.getElementById('form-field-contact-phone')) {
    document.getElementById('form-field-contact-phone').type = 'tel';
  }
  var ftoday = new Date(),
    fyyyy = ftoday.getFullYear(),
    futureyear = fyyyy + 5;
  if (showBasicDatePicker) {
    var minDate = new Date(ftoday.getTime() + adddays * 24 * 60 * 60 * 1000);
    var minDateMonth = minDate.getMonth() + 1;
    var minDateDay = minDate.getDate();
    if (minDateMonth < 10) {
      minDateMonth = '0' + minDateMonth;
    }
    if (minDateDay < 10) {
      minDateDay = '0' + minDateDay;
    }
    $('#form-field-flight-date , #form-field-travel-insurance-start-date, #form-field-travel-insurance-end-date').attr({
      'type': 'date',
      'min': minDate.getFullYear() + '-' + minDateMonth + '-' + minDateDay,
      'max': futureyear + '-' + minDateMonth + '-' + minDateDay
    });
    $('[name="supports-touch"]').val(true);
  } else {
    $('#form-field-flight-date').datepicker({
      minDate: +adddays,
      dateFormat: "dd/mm/yy",
      firstDay: 1,
      numberOfMonths: window.innerWidth > 760 ? 2 : 1
    });
    $(".disable-input input").attr('readonly', true);
    $('[name="supports-touch"]').val(false);
    window.insuranceEnabled && addInsuranceDatepicker();

    // Protection against google translator
    $('.ui-datepicker').addClass('notranslate');
  }
}
$(function () {
  // Empty old cookies
  setCookie("serialized-form", "", 3);

  // Load old form
  $('select').val('');
  loadForm();
  applicationTypeChanger();

  // Validation on blur
  $('#form input:not([type="checkbox"], [type="radio"]), #form select, #form textarea').on('input', function (e) {
    validateFieldsBefore($(this), e);
    validateField($(this));
  });
  $('#form input[type="checkbox"], #form input[name="form[gender]"], #form select, #form textarea, #form #form-field-flight-date').on('change', function (e) {
    validateFieldsBefore($(this), e);
    validateField($(this));
  });
  processForm();

  // set calendar for mobile or desktop
  setCalendarSettings();
  $('.with-hint').tooltip({
    position: {
      at: "center top"
    },
    content: function content() {
      var el = $(this);
      var content = el.attr('title');
      return content;
    }
  });
  initializeTermsLogic();

  //
  // Local form save
  //
  saveForm();

  //
  //  Form label  equal height
  //
  formLabelsEqual();

  //
  // Terms read more
  //
  $('.terms-read-more').click(function (e) {
    var $terms = $('.accept-terms-long');
    $terms.toggleClass('full-text');
  });
  $('input[name=form\\[payment-type\\]][type=radio]').change(function (e) {
    if ($(this)[0].checked === true) {
      var getPrice = $(this)[0].attributes['data-src'].value;
      getPrice = getPrice.split('/');
      $('.priceToChange').text(getPrice[1]);
    }
  });
  if ($('input[name=form\\[payment-type\\]][type=radio]').val()) {
    $('input[name=form\\[payment-type\\]][type=radio]').trigger('change');
  }
  if (window.insuranceEnabled || window.denialProtectionEnabled) {
    loadPrices();
  }

  //
  // Card proccessing form
  //
  cardProcessingForm();

  //
  // Payment form rwd
  //
  var $paymentForm = $('#payment-form');
  var $colFirstName = $('.col-first-name');
  var $colLastName = $('.col-last-name');
  if ($paymentForm.length && $(window).width() <= 720) {
    $colLastName.insertAfter($colFirstName);
  }
  $('#form-field-port-of-arrival').change(function () {
    toggleFastTrack();
  });
  if ($('#form-field-port-of-arrival').val()) {
    $('#form-field-port-of-arrival').trigger('change');
  }
});
function applicationTypeChanger() {
  var $applicationType = $('[name="form[eta-application-type]"]');
  $applicationType.change(function () {
    var selected = undefined;
    if ($applicationType.val()) {
      selected = $applicationType.val().split('-')[0];
    }
    if (typeof selected === "undefined") {
      return;
    }
    if ($.inArray(selected, ["tourist", "business", "transit"]) !== -1) {
      $(".show-if-tourist, .show-if-business, .show-if-transit").addClass('hidden').filter(".show-if-" + selected).removeClass('hidden');
    }
  }).trigger('change');
}
function passportNumberHelper() {
  var $field = $('[name="form[passport-number]"], [name="check[passport_number]"]');
  var validChars = new RegExp("[a-zA-Z0-9]");
  var invalidChars = new RegExp("[oO]");
  $field.on('keypress', function (e) {
    var key = e.keyCode ? e.keyCode : e.which;
    var _char = String.fromCharCode(key);
    if (!validChars.test(_char) && key != 8) {
      e.preventDefault();
      return;
    }
  }).trigger('keypress');
  $field.on('keyup', function () {
    $field.val($field.val().toUpperCase());
  });
}
function cardProcessingForm() {
  var $form = $('#card-processing-form');
  var $btn = $form.find('[type="submit"]');
  var $message = $('.payment-status');
  $form.submit(function (e) {
    e.preventDefault();
    $btn.prop('disabled', true).addClass('loading');
    $message.html("").removeClass('show-error').removeClass('show-success');
    $.ajax({
      url: $form.attr('action'),
      method: "POST",
      dataType: "json",
      data: $form.serialize(),
      success: function success(response) {
        if (response.status == true) {
          window.location.href = $form.attr('data-success-redirect');
          $form.hide();
          $message.html(response.message).removeClass('show-error').addClass('show-success');
        } else {
          $message.html(response.message).removeClass('show-success').addClass('show-error');
        }
        $btn.prop('disabled', false).removeClass('loading');
      },
      error: function error(response) {
        console.log(response.responseText);
        $btn.prop('disabled', false).removeClass('loading');
      }
    });
  });
}
function formLabelsEqual() {
  if ($(window).width() <= 400) return;
  var maxHeight = 0;
  var currentHeight = 0;
  var $columns;
  $('#form .form-row-fields').each(function () {
    maxHeight = 0;
    $columns = $(this).find('.fields-column:visible:not(.clearfix):not(.not-equalize)');
    $columns.each(function () {
      currentHeight = $(this).height();
      if (currentHeight > maxHeight) maxHeight = currentHeight;
    });
    if ($columns.length > 1) {
      $columns.each(function () {
        var $obj = $(this);
        currentHeight = $obj.height();
        if (currentHeight < maxHeight) {
          $obj.css('margin-top', maxHeight - currentHeight);
        }
      });
    }
  });
}
function saveForm() {
  if (typeof Storage === "undefined") return;
  var $form = $('#form');
  var $fields = $form.find('input, select, textarea');
  $fields.change(function () {
    localStorage.setItem(formLocalStorageKey, $form.serialize());
    localStorage.setItem(formLocalStorageKey + '_date', Date.now());
  });
}
function loadForm() {
  if (typeof Storage === "undefined") return;
  var data = localStorage.getItem(formLocalStorageKey);
  if (typeof data === "undefined" || data == "" || data == null) return;
  if ($('#form').length <= 0) return;
  var form = data.split("&");
  var key;
  for (key in form) {
    var elem = form[key];
    var parts = elem.split("=");
    var name = decodeURI(parts[0]);
    var value = decodeURIComponent(parts[1]).replace(/\+/g, ' ');
    var $field = $('[name="' + name + '"]');
    if ($field.attr('type') == "checkbox" || $field.attr('type') == "radio") $('[name="' + name + '"][value="' + value + '"]').prop("checked", true).trigger('change');else $field.val(value).trigger('change');
  }
  $('#form input, #form select').each(function () {
    if ($(this).val()) {
      validateField($(this));
    }
  });
}
function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + expires;
}
function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1);
    if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
  }
  return "";
}
function isValidDate(dateString) {
  // First check for the pattern
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false;

  // Parse the date parts to integers
  var parts = dateString.split("/");
  var day = parseInt(parts[0], 10);
  var month = parseInt(parts[1], 10);
  var year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;
  var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 == 0 || year % 100 != 0 && year % 4 == 0) monthLength[1] = 29;

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
}
;
function validateFieldsBefore($stopBefore, event) {
  $('#form input, #form select').each(function () {
    var $obj = $(this);
    if ($obj.attr('id') == $stopBefore.attr('id')) return false;else validateField($obj);
  });
}
function validateField($field) {
  var isValid = true;
  var fieldType = $field.attr('type');
  var isRequired = $field.attr('data-required') !== undefined;

  // console.log(fieldType, $field.attr('name'));

  if (fieldType == "checkbox") {
    if ($field.prop('checked') == false) isValid = false;
  } else if (fieldType == "radio") {
    var v = $('[name="' + $field.attr('name') + '"]:checked').val();
    if (v == null || typeof v == "undefined") {
      isValid = false;
    }
    if ($field.prop("tagName") !== "INPUT") {
      $field = $field.closest('.radio-column').find('label');
    }
  } else {
    if ($field.val() == null || typeof $field.val() === "undefined") {
      isValid = false;
    } else {
      var f = $field.val();
      if (typeof f == "string" && f.trim() == "") isValid = false;
    }
  }

  // Default email
  if ($field.attr('id') == "form-field-contact-email-repeat") {
    if ($('#form-field-contact-email').val() !== $field.val()) isValid = false;
  }
  if ($field.attr('id') == "form-field-contact-email") {
    validateField($('#form-field-contact-email-repeat'));
  }
  if (validateDateField($field.attr('id')) === false) {
    isValid = false;
  }
  if (fieldType == "email") {
    var regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEx.test($field.val())) {
      isValid = false;
    }
  }
  if ($field.parent().hasClass('hidden-vs') || !$field.is(':visible')) isValid = true;
  if ($field.attr('id') == 'form-field-flight-date' && $field.val() != "" && $field.val() != null) {
    isValid = isValidDate($field.val());
  }
  if ($field.attr('id') == 'form-field-flight-date' && $field.val() != "" && $field.val() != null && !showBasicDatePicker) {
    // input date
    var fieldDateValue = $('#' + $field.attr('id')).val();
    var fieldDateValueArray = fieldDateValue.split('/');
    var datexx = new Date(fieldDateValueArray[2], fieldDateValueArray[1], fieldDateValueArray[0]);
    var dayxx = datexx.getDate();
    var monthxx = datexx.getMonth() + 1;
    var yearxx = datexx.getFullYear();

    //today
    var ftoday = new Date();
    var fdd = ftoday.getDate();
    var fmm = ftoday.getMonth() + 1; //January is 0!
    var fyyyy = ftoday.getFullYear();
    var fdayw = ftoday.getDay();
    var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    var firstDate = new Date(yearxx, monthxx, dayxx);
    var secondDate = new Date(fyyyy, fmm, fdd); //today
    var diffDays = Math.round((firstDate.getTime() - secondDate.getTime()) / oneDay);
    if (diffDays > adddays) {
      isValid = true;
    } else {
      isValid = false;
    }
    if (yearxx < fyyyy) isValid = false;
  } else if ($field.attr('id') == 'form-field-flight-date' && $field.val() != "" && $field.val() != null && showBasicDatePicker) {
    var arrivalField = $('#form-field-flight-date');
    var arrivalFieldVal = arrivalField.val();
    var arrivalFieldValMin = arrivalField.attr('min');
    var arrivalFieldValMax = arrivalField.attr('max');
    var dVal = new Date(arrivalFieldVal);
    var dMin = new Date(arrivalFieldValMin);
    var dMax = new Date(arrivalFieldValMax);
    if (dMin.getTime() > dVal.getTime() || dMax.getTime() < dVal.getTime()) {
      isValid = false;
    } else {
      isValid = true;
    }
  }
  markField($field, isValid);
  if (window.insuranceEnabled && !validateInsuranceFields($field)) {
    isValid = false;
  }
  return isValid;
}
function markField($field, isValid) {
  if (isValid) {
    $field.removeClass('with-error').addClass('with-success');
    var errorText = $field.closest('.fields-column').find('.form-field-error');
    if (errorText) {
      errorText.text('').addClass('hidden');
    }
    if (['form[terms]', 'form[travel-insurance]', 'form[fast-track]'].includes($field.attr('name'))) {
      $field.closest('label').removeClass('with-error').addClass('with-success');
    }
    return;
  }
  $field.removeClass('with-success').addClass('with-error');
  if (['form[terms]', 'form[travel-insurance]', 'form[fast-track]'].includes($field.attr('name'))) {
    $field.closest('label').removeClass('with-success').addClass('with-error');
  }
}
function validateDateField(fieldIdName) {
  var isValid = true;
  var idName = !fieldIdName ? fieldIdName : fieldIdName.replace('-day', '').replace('-month', '').replace('-year', '');
  if (idName != fieldIdName) {
    var selectedDate = loadDate(idName);
    if (selectedDate.date !== null) {
      var className = {
        add: 'with-success',
        remove: 'with-error'
      };
      var today = new Date(new Date().setHours(0, 0, 0, 0));
      if (!validateMonthLength(selectedDate.selectedValues)) {
        isValid = false;
      }
      if (idName.replace('birth') != idName && selectedDate.date.getTime() > today.getTime() || idName.replace('passport-date') != idName && !validatePassportDate(selectedDate) || idName.replace('passport-expire') != idName && !validatePassportExpire(selectedDate)) {
        isValid = false;
      }
      if (!isValid) {
        className.add = 'with-error';
        className.remove = 'with-success';
      }
      $('#' + idName + '-day').removeClass(className.remove).addClass(className.add);
      $('#' + idName + '-month').removeClass(className.remove).addClass(className.add);
      $('#' + idName + '-year').removeClass(className.remove).addClass(className.add);
    }
  }
  return isValid;
}
function validateMonthLength(selectedValues) {
  var selectedMonthLength = new Date(selectedValues.year, selectedValues.month - 1, 0);
  return selectedMonthLength.getDate() >= selectedValues.day;
}
function loadDate(idName) {
  var selectedValues = {
    day: parseInt($('#' + idName + '-day option:selected').text()) || null,
    month: $('#' + idName + '-month option:selected').prevAll().length + 1 || null,
    year: parseInt($('#' + idName + '-year option:selected').text()) || null
  };
  return {
    date: selectedValues.day && selectedValues.month && selectedValues.year ? new Date(selectedValues.year, selectedValues.month - 2, selectedValues.day) : null,
    selectedValues: selectedValues
  };
}
function validatePassportDate(passportDate) {
  var birthDate = loadDate('form-field-birth');
  var today = new Date(new Date().setHours(0, 0, 0, 0));
  return passportDate.date == null || birthDate.date == null || passportDate.date.getTime() > birthDate.date.getTime() && passportDate.date.getTime() <= today.getTime();
}
function validatePassportExpire(passportDateExpire) {
  var passportDate = loadDate('form-field-passport-date');
  var today = new Date(new Date().setHours(0, 0, 0, 0));
  return passportDate.date == null || passportDateExpire.date == null || passportDateExpire.date.getTime() > passportDate.date.getTime() && passportDateExpire.date.getTime() > today.getTime();
}
function processForm() {
  function validateBeforeSubmit() {
    var $form = $('#form');
    var $fields = $form.find('[data-required]');
    var errors = 0;
    var focused = false;
    var $validationDataInput = $('[name="form[validation-data]"]');
    $validationDataInput.val("");
    $('.with-error').removeClass('with-error');
    $fields.each(function () {
      var $obj = $(this);
      var visible = $obj.is(':visible') && !$obj.parent().hasClass('hidden-vs');
      if (visible) {
        // match form name
        var regex = new RegExp(/^form\[(.*?)\]$/);
        var match = regex.exec($obj.attr('name'));
        var isValid = validateField($obj);
        $validationDataInput.val($validationDataInput.val() + match[1] + ";");
        if (!isValid) {
          if (!focused) {
            $('html, body').scrollTop($obj.offset().top - 250);
            $obj.focus();
            focused = true;
          }
          errors++;
        }
      }
    });
    return errors;
  }
  $('#form button[type=submit]').click(function (e) {
    if (document.querySelector('input[name="form[terms]"]').checkValidity) {
      if (validateBeforeSubmit() === 0) {
        if (document.querySelector('input[name="form[terms]"]').checkValidity() === false) {
          $('input[name="form[terms]"]').closest('label').addClass('with-error').removeClass('with-success');
        }
      } else {
        e.preventDefault();
        $('#form').trigger('submit');
      }
    }
  });
  $('#form').submit(function (e) {
    e.preventDefault();
    var $form = $(this);
    var $submitBtn = $form.find('[type="submit"]');
    var errors = validateBeforeSubmit();
    if (errors == 0) {
      // send form
      var submitBtnText = $submitBtn.text();
      $submitBtn.prop('disabled', true).addClass('loading').html('<div class="lds-ring"><div></div><div></div><div></div><div></div></div>');
      $.ajax({
        url: $form.attr('action'),
        dataType: "json",
        method: "POST",
        data: $form.serialize(),
        success: function success(response) {
          if (response.status == false) {
            alert(formErrorMessage);
            console.log(response);
          } else {
            if (typeof Storage !== "undefined") {
              localStorage.removeItem(formLocalStorageKey);
            }
            window.location.href = response.redirectURL;
            $submitBtn.removeClass('loading').text(submitBtnText);
            return;
          }
          $submitBtn.prop('disabled', false).removeClass('loading').text(submitBtnText);
        },
        error: function error(response) {
          alert(formErrorMessage);
          $submitBtn.prop('disabled', false).removeClass('loading').text(submitBtnText);
        }
      });
    } else {
      alert(translations['form field warning']);
    }
  });
}
cvvInfo();
function cvvInfo() {
  var circle = $('.green-hint');
  var infoBox = $('.cvv-information');
  if (window.innerWidth <= 800) {
    $(window).on('click', function (event) {
      if (!event.target.classList.contains('green-hint')) {
        infoBox.addClass('hidden');
      }
    });
    circle.on('click', function (event) {
      infoBox.removeClass('hidden');
    });
  } else {
    circle.on('mouseover', function (event) {
      infoBox.removeClass('hidden');
    });
    circle.on('mouseout', function (event) {
      infoBox.addClass('hidden');
    });
  }
}

// yellow fever note
var yellowFeverStatement = [13361,
// Angola
13494,
// Argentina
13370,
// Benin
13502,
// Bolivia
13495,
// Brazil
13397,
// Burkina
13364,
// Burundi
13367,
// CAR
13366,
// Chad
13403,
// China
13498,
// Colombia
13378,
// Core Divoire
13504,
// Ecuador
13387,
// Equatorial Guinea
13371,
// Ethiopia
13505,
// French Guiana
13373,
// Gabon
13374,
// Gambia
13376,
// Guinea
13377,
// Guinea-Bissau
13379,
// Liberia
13382,
// Mali
13383,
// Mauritania
13385,
// Niger
13464,
// Panama
13499,
// Peru
13388,
// Rwanda
13538,
// Sao Tome
13389,
// Senegal
13390,
// Sierra Leone
13391,
// Somalia
13394,
// Sudan
13503,
// Suriname
13355,
// Tanzania
13396,
// Togo
13360,
// Uganda
13501 // Venezuela
];

$('[name="form[nationality-country]"]').change(function (elem) {
  var countryId = parseInt(elem.target.value),
    yellowFeverNote = $(".yellow-fever-note");
  if (yellowFeverStatement.indexOf(countryId) !== -1) {
    if (yellowFeverNote.hasClass('hidden')) {
      yellowFeverNote.removeClass('hidden');
    }
  } else {
    if (!yellowFeverNote.hasClass('hidden')) {
      yellowFeverNote.addClass('hidden');
    }
  }
});
function showError(target, event, message) {
  event.preventDefault();
  var errorText = target.parentNode.querySelector('.form-field-error');
  target.classList.remove('with-success');
  target.classList.add('with-error');
  errorText.classList.remove('hidden');
  errorText.innerText = message;
}
$.fn.preventNonLatin = function () {
  /*
  CYRYLIC:
      u0400 - u052F
      u1C80 - u1C88
      u2DE0 - u2DFF
      uA640 - uA69F
  ARABIC SIGNS:
      u0600 - u077F
      u08A0 - u08BD
      u08D3 - u08FF
  CHINESE SIGNS:
      u2E80 - u2E99
      u2E9B - u2EF3
      u2F00 - u2FD5
      u3000 - u303F
      u3105 - u312F
      u3131 - u318E
      u3190 - u31BA
      u31C0 - u31E3
      u3200 - u321E
      u3220 - u32FE
      u3300 - u9FFF
      uA840 - uA877
      uF900 - uFA6D
      uFA70 - uFAD9
  */
  var nonLatinPattern = /[\u0400-\u052f\u1C80-\u1C88\u2DE0-\u2DFF\uA640-\uA69F\u0600-\u077F\u08A0-\u08BD\u08D3-\u08FF\u2E80-\u2E99\u2E9B-\u2EF3\u2F00-\u2FD5\u3000-\u303F\u3105-\u312F\u3131-\u318E\u3190-\u31BA\u31C0-\u31E3\u3200-\u321E\u3220-\u32FE\u3300-\u9FFF\uA840-\uA877\uF900-\uFA6D\uFA70-\uFAD9]/;
  this.keypress(function (e) {
    if (e.key.replace(nonLatinPattern, '') == '') {
      showError(this, e, translations['form label nonlatin error']);
    }
  });
  this.on('paste', function (e) {
    var pasteData;
    if (window.clipboardData !== undefined && window.clipboardData.getData !== undefined) {
      // IE
      pasteData = window.clipboardData.getData('text');
    } else if (e.originalEvent.clipboardData !== undefined && e.originalEvent.clipboardData.getData !== undefined) {
      pasteData = e.originalEvent.clipboardData.getData('text');
    }
    if (pasteData.match(nonLatinPattern)) {
      showError(this, e, translations['form label nonlatin error']);
    }
  });
};
function getPrices(resolve) {
  var req = new XMLHttpRequest();
  var formdata = new FormData();
  var nationality = $('[name="form[nationality-country]"]'),
    visaType = $('[name="form[eta-application-type]"]');
  if (nationality.val() !== null && nationality.val() !== undefined && typeof nationality.val() !== "undefined") {
    formdata.append('nationality', nationality.val());
  }
  if (visaType.val() !== null && visaType.val() !== undefined && typeof visaType.val() !== "undefined") {
    formdata.append('visaType', visaType.val());
  }
  formdata.append('currency', 'EUR');
  req.open('POST', '/prices/', true);
  req.onreadystatechange = function () {
    if (req.readyState === 4) {
      if (req.status === 200) {
        resolve(JSON.parse(req.responseText));
      } else {
        console.error('CANNOT GET PRICES');
      }
    }
  };
  req.send(formdata);
}
$('#form-field-name').preventNonLatin();
$('#form-field-surname').preventNonLatin();
function toggleFastTrack() {
  var portId = $('#form-field-port-of-arrival').val();
  var fastTrackPortsId = Object.keys(fastTrackPorts);
  var isPortEligibleForFastTrack = fastTrackPortsId.includes(portId);
  showFastTrackQuestion(isPortEligibleForFastTrack);
}
function showFastTrackQuestion(isEnabled) {
  if (isEnabled) {
    $(".fast-track-question").removeClass('hidden');
    showFastTrackFields();
  } else {
    $(".fast-track-question").addClass('hidden');
    hidFastTrackFlightFields();
  }
}
function showFastTrackFields() {
  var fastTrackQuestionRadio = $('[name="form[fast-track]"]');
  fastTrackQuestionRadio.on('change', function () {
    $('[name="form[fast-track]"]').closest('label').removeClass('with-error').addClass('with-success');
    if ($('[name="form[fast-track]"]:checked').val() === "1") {
      $('.fast-track-cnt').removeClass('hidden');
      $('.fast-track-flight-fields').removeClass('hidden');
      setFastTrackPrice();
    } else {
      $('.fast-track-cnt').addClass('hidden');
      hidFastTrackFlightFields();
    }
  }).trigger('change');
  fastTrackQuestionRadio.closest('.fields-column').removeClass('with-error');
  fastTrackQuestionRadio.closest('label').removeClass('with-error');
}
function setFastTrackPrice() {
  var portId = $('#form-field-port-of-arrival').val();
  var productPrice = fastTrackPorts[portId] ? fastTrackPorts[portId].productPrice : null;
  if (productPrice) {
    $('.fast-track-price').removeClass('hidden');
    $('.form-fast-track-price').text(productPrice + ' EUR');
  }
}
function hidFastTrackFlightFields() {
  $('.fast-track-flight-fields').addClass('hidden');
  $('#form-field-flight-number').val('');
  $('#form-field-flight-time').val('');
}
function initializeTermsLogic() {
  $('input[name="form[terms-accept-all]"]').on('change', function () {
    $('.terms-margin input[type="checkbox"]').prop('checked', $(this).prop('checked')).trigger('change');
  });
  $('.terms-margin input[type="checkbox"]').on('change', function () {
    if (!$(this).prop('checked')) {
      $('input[name="form[terms-accept-all]"]').prop('checked', false);
    } else {
      var allChecked = true;
      $('.terms-margin input[type="checkbox"]').each(function () {
        if (!$(this).prop('checked')) {
          allChecked = false;
        }
      });
      $('input[name="form[terms-accept-all]"]').prop('checked', allChecked);
    }
  });
}
function loadPrices() {
  getPrices(function (data) {
    if (window.denialProtectionEnabled) {
      updateDenialProtectionPrice(data);
    }
    if (window.insuranceEnabled) {
      loadInsurancePrices(data);
    }
  });
}
function updateDenialProtectionPrice(prices) {
  if (!window.denialProtectionEnabled || !prices['denial_protection']) {
    return;
  }
  var denialProtectionLabel = $('.denial-protection-section .form-row-heading');
  if (!denialProtectionLabel.text().includes('+')) {
    var denialProtectionPrice = prices['denial_protection'].toFixed(2);
    denialProtectionLabel.append("(+".concat(denialProtectionPrice, " ").concat(prices.currency, ")"));
  }
}